import {QueryClient, QueryClientProvider} from "react-query";
import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import {ACard} from "@atiautomacao/ati-ui-library";
import {TeleObjectType} from "../../../../Shared/Types/TeleObjectType";
import {EquipmentClassType} from "../../../../Shared/Types/EquipmentClass";
import {
    FindPointClassByIdReactQuery,
    SavePointClassReactQuery,
    DeleteTeleObjectAlarmConditionReactQuery,
    CommandValueReactQuery
} from "./PointClassReactQuery";
import {useSnackbar} from "notistack";
import AlarmConditionTab from "./AlarmConditionTab";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {MenuProps} from "../../../../Utils/PropsStyles";
import {TextRequired} from "../../../../Utils/ElementsHtmlUtil";
import {AddCircle} from "@mui/icons-material";
import ConfirmDialog from "../../../../Shared/Components/ConfirmDialog";
import {CommandValueTab} from "./CommandValueTab";
import {CommandValue} from "../../../../Shared/Types/PowerStation";
import axios from "axios";
import {hasPermission} from "../../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../../Config/Constants";
import {useAppSelector} from "../../../../Config/Hooks";

interface PointClassModalProps {
    data: TeleObjectType | undefined;
    onCloseModal: any;
    open: boolean;
    dataForm?: any;
    equipmentClass?: EquipmentClassType;
    refreshPointMap: any;
    index: number;
}

interface Normalization {
    index: number;
    value: string;
    id: number | null;
}

interface AlarmSeverity {
    index: number;
    value: number;
    id: number | null;
}

interface AlarmedName {
    index: number;
    value: number;
    id: number | null;
}

interface AlarmedSound {
    index: number;
    hasAlarmSound: boolean;
    id: number | null;
}

const style = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '1px solid #666',
    height: '65%',
    borderRadius: '25px'
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: Readonly<TabPanelProps>) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const ModalPointClass = ({data, open, onCloseModal, dataForm, equipmentClass, refreshPointMap, index}: PointClassModalProps) => {
    const [name, setName] = useState("");
    const [sigla, setSigla] = useState("");
    const [point, setPoint] = useState<number | null>(null);
    const [variation, setVariation] = useState<number | null>(null);
    const [underLimit, setUnderLimit] = useState<number | null>(null);
    const [upperLimit, setUpperLimit] = useState<number | null>(null);
    const [commandVariables, setCommandVariables] = useState("");
    const [address, setAddress] = useState("");
    const [adjustment, setAdjustment] = useState("");
    const [note, setNote] = useState("");
    const [pointTag, setPointTag] = useState<string | null>("");
    const [inputType, setInputType] = useState<string | null>("FISICO");
    const [isDefault, setIsDefault] = useState<boolean | null>(true);
    const [teleObjectType, setTeleObjectType] = useState(-1);
    const [teleObjectSpecificationType, setTeleObjectSpecificationType] = useState(-1);
    const [modBusObjectType, setModBusObjectType] = useState(-1);
    const [modBusDataType, setModBusDataType] = useState(-1);
    const [teleObjectDataType, setTeleObjectDataType] = useState(-1);
    const [unit, setUnit] = useState(-1);

    const [teleObjectTypesList, setTeleObjectTypesList] = useState<any[]>([]);
    const [teleObjectSpecificationTypesList, setTeleObjectSpecificationTypesList] = useState<any[]>([]);
    const [alarmSeveritiesList, setAlarmSeveritiesList] = useState<any[]>([]);
    const [modBusObjectTypesList, setModBusObjectTypesList] = useState<any[]>([]);
    const [modBusDataTypesList, setModBusDataTypesList] = useState<any[]>([]);
    const [teleObjectDataTypeList, setTeleObjectDataTypeList] = useState<any[]>([]);
    const [unitList, setUnitList] = useState<any[]>([]);

    const mutationSave = SavePointClassReactQuery();
    const mutationDeleteDeleteAlarmCondition = DeleteTeleObjectAlarmConditionReactQuery();
    const mutationFind = FindPointClassByIdReactQuery();

    const [dataToEdit, setDataToEdit] = useState<TeleObjectType | undefined>(undefined);

    const [valueTab, setValueTab] = React.useState(0);

    const [commandValueList, setCommandValueList] = useState<Array<CommandValue>>([])
    const {data: commandValueData} = CommandValueReactQuery(dataToEdit?.id)
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToDefault = hasPermission(account?.authoritySet, [AUTHORITIES.SYSADMIN]);

    const handleChangeTab = (event: React.SyntheticEvent | null, newValue: number) => {
        if(newValue < listSeverityTabs.length + 1){
            setValueTab(newValue);
        } else {
            setListSeverityTabs((prevListSeverityTabs) => {
                return [...prevListSeverityTabs, prevListSeverityTabs[prevListSeverityTabs.length - 1] + 1];
            });
        }
    };

    useEffect(() => {
        if(open) setValueTab(0)
    }, [open]);

    useEffect(() => {
        if(commandValueData !== undefined){
            setCommandValueList(commandValueData.data)
        }
    }, [commandValueData]);

    useEffect(() => {
        if(teleObjectType !== 1){
            setCommandValueList([])
        }
    }, [teleObjectType]);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(data?.id){
            mutationFind.mutate(Number(data.id), {
                onSuccess: (dataFound) => {
                    setDataToEdit(dataFound.data);
                }
            });
        } else {
            setDataToEdit(data);
            setListSeverityTabs([1]);
        }
    }, [data]);

    useEffect(() => {
        if(dataForm){
            let typeDefault = {id: -1, name: "Selecione o Tipo"};
            let tobTypes = [...dataForm.teleObjectTypes, typeDefault];
            setTeleObjectTypesList(sortArray(tobTypes));
            let tobsTypes = [...dataForm.teleObjectSpecificationTypes, typeDefault];
            setTeleObjectSpecificationTypesList(sortArray(tobsTypes));
            let alarms = [...dataForm.alarmSeverities, typeDefault];
            setAlarmSeveritiesList(sortArray(alarms));
            let modObjects = [...dataForm.modBusObjectTypes, typeDefault];
            setModBusObjectTypesList(sortArray(modObjects));
            let modData = [...dataForm.modBusDataTypes, typeDefault];
            setModBusDataTypesList(sortArray(modData));
            let teleObjectData = [...dataForm.teleObjectDataTypes, typeDefault];
            setTeleObjectDataTypeList(sortArray(teleObjectData));
            let unities = [...dataForm.unities, typeDefault];
            setUnitList(sortArray(unities));
        }
    }, [dataForm]);

    useEffect(() => {
        if(dataToEdit){
            setName(dataToEdit.name);
            setPoint(dataToEdit.point);
            setVariation(dataToEdit.variation);
            setUnderLimit(dataToEdit.discardLowerLimit);
            setUpperLimit(dataToEdit.discardUpperLimit);
            setTeleObjectType(dataToEdit?.teleObjectType?.id ?? -1);
            setTeleObjectSpecificationType(dataToEdit?.teleObjectSpecificationType?.id ?? -1);
            setModBusObjectType(dataToEdit?.modBusObjectType?.id ?? -1);
            setModBusDataType(dataToEdit?.modBusDataType?.id ?? -1);
            setAddress(dataToEdit.address);
            setAdjustment(dataToEdit?.teleObjectConvertExpression);
            setNote(dataToEdit.description);
            setUnit(dataToEdit?.unity?.id ?? -1);
            setCommandVariables(dataToEdit.commandVariables);
            setTeleObjectDataType(dataToEdit?.teleObjectDataType?.id ?? -1);
            setPointTag(dataToEdit.pointTag);
            setInputType(dataToEdit.inputType);
            setIsDefault(dataToEdit.isDefault);

            if(dataToEdit.teleObjectAlarmConditionsEntity.length > 0) {
                let alarmList: AlarmSeverity[] = [];
                let conditionList: Normalization[] = [];
                let alarmedNameList: AlarmedName[] = [];
                let alarmedSoundList: AlarmedSound[] = [];
                let indexList: number[] = [];
                dataToEdit.teleObjectAlarmConditionsEntity.forEach((alarm, index) => {
                    let alarmValue = {index: index + 1, value: alarm.severity.id, id: alarm.id};
                    let conditionValue = {index: index + 1, value: alarm.normalAlarmCondition, id: alarm.id};
                    let alarmedName = {index: index + 1, value: alarm.alarmedName, id: alarm.id};
                    let alarmedSound = {index: index + 1, hasAlarmSound: alarm.hasAlarmSound, id: alarm.id};
                    indexList.push(index + 1);
                    alarmList.push(alarmValue);
                    conditionList.push(conditionValue);
                    alarmedNameList.push(alarmedName);
                    alarmedSoundList.push(alarmedSound);
                });
                setListSeverityTabs(indexList);
                setAlarmSeveritiesValue(alarmList);
                setConditionsValue(conditionList);
                setAlarmedNamesValue(alarmedNameList);
                setAlarmSoundValue(alarmedSoundList);
            }
        }

    }, [dataToEdit]);

    function sortArray(arrayValue: any[]) {
        return arrayValue.sort((a: any,b: any) => a.id - b.id);
    }

    const [alarmSoundValue, setAlarmSoundValue] = useState<AlarmedSound[]>([]);
    const [conditionsValue, setConditionsValue] = useState<Normalization[]>([]);
    const [alarmSeveritiesValue, setAlarmSeveritiesValue] = useState<AlarmSeverity[]>([]);
    const [alarmedNamesValue, setAlarmedNamesValue] = useState<AlarmedName[]>([]);
    const [listSeverityTabs, setListSeverityTabs] = useState([1]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedToDeleteIndex, setSelectedToDeleteIndex] = useState<any>(null);

    async function update(data:any, apiUrl: string)  {
        try {
            const response = await axios.put(apiUrl, data);
            return response.data;

        } catch (error:any) {
            throw error;
        }
    }

    function savePointClass() {
        let selectedTeleObjectType = dataForm.teleObjectTypes.find((tob: any) => tob.id === teleObjectType);
        let selectedTeleObjectSpecificationType = dataForm.teleObjectSpecificationTypes.find((tobst: any) => tobst.id === teleObjectSpecificationType);
        let selectedModBusObject = dataForm.modBusObjectTypes.find((modObject: any) => modObject.id === modBusObjectType);
        let selectedModBusData = dataForm.modBusDataTypes.find((modData: any) => modData.id === modBusDataType);
        let selectedTeleObjectDataType = dataForm.teleObjectDataTypes.find((tobDataType: any) => tobDataType.id === teleObjectDataType);
        let selectedUnit = dataForm.unities.find((unt: any) => unt.id === unit);
        let alarmOk = true;
        let alarmConditionsList: any[] = [];

        if(conditionsValue.length > 0) {
            conditionsValue.forEach(cdt => {
                
                let alarmIndex = alarmSeveritiesValue.find(alm => alm.index === cdt.index);
                let alarmSelected = alarmSeveritiesList.find(a => a.id === alarmIndex?.value);
                let alarmedNameSelected = alarmedNamesValue.find(a => a.index === cdt.index);
                let alarmSoundSelected = alarmSoundValue.find(a => a.index === cdt.index);
                
                const isAnyFieldFilled = cdt.value || alarmSelected || alarmedNameSelected?.value;
                const areAllFieldsFilled = cdt.value && alarmSelected && alarmedNameSelected?.value;

                if (isAnyFieldFilled && !areAllFieldsFilled) {
                    enqueueSnackbar("Todos os campos de condição de alarme devem ser preenchidos.", { variant: 'error' });
                    alarmOk = false;
                    return;
                }

                if (areAllFieldsFilled) {
                    alarmConditionsList.push({
                        id: cdt.id ? cdt.id : null,
                        normalAlarmCondition: cdt.value,
                        alarmedName: alarmedNameSelected?.value,
                        hasAlarmSound: alarmSoundSelected?.hasAlarmSound ?? false,
                        severity: alarmSelected
                    });
                }
            })
        }

        if (!isAlarmConditionsListValid(alarmSeveritiesValue)) {
            alarmOk = false;
            enqueueSnackbar("É necessário preencher corretamente todos os campos do Alarme/Severidade.", {variant: 'error'});
        }

        let dataToSave = {
            id: data?.id ? data.id : null,
            name: name,
            equipmentClass: equipmentClass,
            teleObjectType: selectedTeleObjectType,
            teleObjectSpecificationType: selectedTeleObjectSpecificationType,
            address: address,
            teleObjectConvertExpression: adjustment,
            unity: selectedUnit,
            description: note,
            isDefault: isDefault,
            modBusObjectType: selectedModBusObject,
            point: point,
            modBusDataType: selectedModBusData,
            variation: variation,
            discardLowerLimit: underLimit,
            discardUpperLimit: upperLimit,
            teleObjectDataType: selectedTeleObjectDataType,
            commandVariables: commandVariables,
            index: data?.index != null ? data.index : index,
            pointTag: pointTag,
            inputType: inputType,
            teleObjectAlarmConditionsEntity: alarmConditionsList,
            commandList: commandValueList
        }
        if(checkNullToSave(dataToSave) && alarmOk) {
            if (equipmentClass === undefined) {
                refreshPointMap(dataToSave);
            } else {
                let teleConfigSaved: undefined
                mutationSave.mutate(dataToSave, {
                    onSuccess: (data) => {
                        refreshPointMap(data.data);
                        teleConfigSaved = data.data
                        if(teleConfigSaved !== undefined){
                            let commandValues: any[] = commandValueList;

                            commandValues.forEach((cv: any) => {
                                cv.teleObjectConfig = teleConfigSaved;
                            })
                            return update(commandValues, "api/command-value").then(response => {
                                console.log(response);
                            });
                        }
                    }
                });

            }
            handleClose();
        } else {
            enqueueSnackbar("Verifique os campos obrigatórios na aba Informações antes de tentar salvar o dado.", {variant: 'error'});
        }
    }

    function isAlarmConditionsListValid(alarmConditionsList: any[]): boolean {
        for (const condition of alarmConditionsList) {
            const { normalAlarmCondition, alarmedName, severity } = condition;
            const isAnyFieldFilled = normalAlarmCondition || alarmedName || severity;
            const areAllFieldsFilled = normalAlarmCondition && alarmedName && severity;

            if (isAnyFieldFilled && !areAllFieldsFilled) {
                return false;
            }
        }
        return true;
    }

    function handleClose(){
        setName('');
        setPoint(null);
        setVariation(null);
        setUnderLimit(null);
        setUpperLimit(null);
        setTeleObjectType(-1);
        setTeleObjectSpecificationType(-1);
        setModBusObjectType(-11);
        setModBusDataType(-1);
        setAddress('');
        setAdjustment('');
        setNote('');
        setUnit(-1);
        setCommandVariables('');
        setTeleObjectDataType(-1);
        setInputType('');
        setAlarmSeveritiesValue([]);
        setAlarmedNamesValue([]);
        setConditionsValue([]);
        setAlarmSoundValue([]);
        setListSeverityTabs([]);
        setSigla("");
        setIsDefault(true)
        setCommandValueList([])

        onCloseModal();
    }

    const handleInputTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputType((event.target as HTMLInputElement).value);
    };

    const handleDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDefault(event.target.value === "true");
    };

    function checkNullToSave(data: any){
        let canSave = true;

        let requiredKeysLogic = ["inputType", "name",  "teleObjectType", "modBusDataType"];
        let requiredKeysFisico = ["inputType", "name", "point", "teleObjectSpecificationType", "teleObjectType", "modBusDataType"];
        if(inputType === "FISICO"){
            requiredKeysFisico.forEach(key => {
                if(data[key] === null || data[key] === undefined || data[key] === ""){
                    canSave = false;
                }
            })
        } else if(inputType === "LOGICO") {
            requiredKeysLogic.forEach(key => {
                if(data[key] === null || data[key] === undefined || data[key] === ""){
                    canSave = false;
                }
            })
        } else if(inputType === "") {
            canSave = false;
        }

        return canSave;
    }

    function handleSetNormalization(newNormalization: Normalization){
        let conditions = conditionsValue;
        if(conditions.length > 0){
            conditions.forEach((cdt, index) => {
                if (cdt.index === newNormalization.index) {
                    conditions.splice(index, 1);
                }
            });
            conditions.push(newNormalization);
            setConditionsValue([...conditions]);
        }else{
            setConditionsValue([newNormalization]);
        }
    }

    function handleSetAlarmSound(alarmSound: AlarmedSound) {
        
        let conditions = alarmSoundValue;
        if(conditions.length > 0){
            conditions.forEach((cdt, index) => {
                if (cdt.index === alarmSound.index) {
                    conditions.splice(index, 1);
                }
            });
            conditions.push(alarmSound);
            setAlarmSoundValue([...conditions]);
        }else{
            setAlarmSoundValue([alarmSound]);
        }
    }

    function handleSetAlarmSeverity(newAlarm: AlarmSeverity){
        let alarmValues = alarmSeveritiesValue;
        if(alarmValues.length > 0){
            alarmValues.forEach((cdt, index) => {
                if (cdt.index === newAlarm.index) {
                    alarmValues.splice(index, 1);
                }
            });
            alarmValues.push(newAlarm);
            setAlarmSeveritiesValue([...alarmValues]);
        }else{
            setAlarmSeveritiesValue([newAlarm]);
        }
    }

    function handleSetAlarmedName(newAlarm: AlarmedName){
        let alarmValues = alarmedNamesValue;
        if(alarmValues.length > 0) {
            alarmValues.forEach((cdt, index) => {
                if (cdt.index === newAlarm.index) {
                    alarmValues.splice(index, 1);
                }
            });
            alarmValues.push(newAlarm);
            setAlarmedNamesValue([...alarmValues]);
        }else{
            setAlarmedNamesValue([newAlarm]);
        }
    }

    const handleDeleteAlarmCondition = useCallback((index: any) => {
        setSelectedToDeleteIndex(index);
        setOpenDialog(true);
    }, []);

    const handleDialogConfirm = async () => {
        const newConditionsValue = conditionsValue.filter(element => element.index !== selectedToDeleteIndex);
        const newAlarmedNamesValue = alarmedNamesValue.filter(element => element.index !== selectedToDeleteIndex);
        const newAlarmSeveritiesValue = alarmSeveritiesValue.filter(element => element.index !== selectedToDeleteIndex);
        const newAlarmSoundValue = alarmSoundValue.filter(element => element.index !== selectedToDeleteIndex);
        const newTabs = listSeverityTabs.filter(element => element !== selectedToDeleteIndex);
        setConditionsValue(newConditionsValue);
        setAlarmedNamesValue(newAlarmedNamesValue);
        setAlarmSeveritiesValue(newAlarmSeveritiesValue);
        setAlarmSoundValue(newAlarmSoundValue);
        setListSeverityTabs(newTabs);
        const id = alarmSeveritiesValue.find(element => element.index === selectedToDeleteIndex)?.id;
        if(id != null) {
            mutationDeleteDeleteAlarmCondition.mutate(id);
        }

        setSelectedToDeleteIndex(null);
        handleDialogToggle();
    }

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    useEffect(() => {
        if(listSeverityTabs.length === 0){
            setListSeverityTabs([1]);
        }
    }, [open]);

    const onSaveCommands = (commands: Array<CommandValue>) => {
        if(teleObjectType === 1){
            setCommandValueList(commands)
        }
    }

    const [commandIdsDeleted, setCommandIdsDeleted] = useState<number[]>([]);

    const onDeleteCommand = (commandIds: Array<number>) => {
        setCommandIdsDeleted(commandIds)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid sx={style}>
                <ACard
                    title={dataToEdit?.name ? `Edição: ${dataToEdit?.name}` : "Novo"}
                    styleProps={{
                        cardStyle:{overflow: valueTab === 0 ? "auto" : "hidden", padding: 2, height: '100%'}
                    }}
                    footerActions={
                        <>
                            <Button
                                variant="contained"
                                color={"primary"}
                                style={{marginBottom: 20, marginRight: 10}}
                                onClick={handleClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color={"primary"}
                                style={{marginBottom: 20}}
                                onClick={savePointClass}

                            >
                                Gravar
                            </Button>
                        </>
                    }
                >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={valueTab}
                              onChange={handleChangeTab}
                              variant="scrollable"
                              scrollButtons
                              allowScrollButtonsMobile
                              aria-label="basic tabs example">
                            <Tab label="Informações" {...a11yProps(0)} />
                            <Tab key={"severity"} label={"Severidade"} {...a11yProps(1)}/>
                            <Tab disabled={teleObjectType !== 1} key={"command"} label={"Valor de Comando"} {...a11yProps(2)}/>
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={valueTab} index={0}>
                    <Grid container spacing={2}>

                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="class-name">Nome {TextRequired()}</InputLabel>
                                <TextField
                                    error={name === ""}
                                    fullWidth
                                    id="class-name"
                                    variant="standard"
                                    placeholder={"Nome"}
                                    onChange={(value) => setName(value.target.value)}
                                    value={name}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="class-name">Sigla</InputLabel>
                                <TextField
                                    fullWidth
                                    id="class-name"
                                    variant="standard"
                                    placeholder={"Sigla"}
                                    onChange={(value) => setSigla(value.target.value)}
                                    value={sigla}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="selectTipo">Tipo de Teleobjeto {TextRequired()}</InputLabel>
                                <Select
                                    MenuProps={MenuProps}
                                    error={teleObjectType === -1}
                                    fullWidth
                                    labelId="selectTipo"
                                    id="selectTeleObjectType"
                                    value={teleObjectType}
                                    label="Tipo"
                                    onChange={(value) => setTeleObjectType(value.target.value as number)}
                                    variant={"standard"}
                                    required
                                >
                                    {
                                        teleObjectTypesList.map((type: any) => {
                                            return (
                                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="selectTipoDado">Tipo de Conversão {TextRequired()}</InputLabel>
                                <Select
                                    MenuProps={MenuProps}
                                    fullWidth
                                    labelId="selectTipoDado"
                                    id="selectTeleObjectType"
                                    value={modBusDataType}
                                    label="Tipo de Dados"
                                    onChange={(value) => setModBusDataType(value.target.value as number)}
                                    variant={"standard"}
                                    required
                                >
                                    {
                                        modBusDataTypesList.map((type: any) => {
                                            return (
                                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="pointTag">Tag do Ponto</InputLabel>
                                <TextField
                                    fullWidth
                                    id="pointTag"
                                    variant="standard"
                                    placeholder={"Tag do Ponto"}
                                    onChange={(value) => setPointTag(value.target.value)}
                                    value={pointTag}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="radioInputType">Tipo de Input {TextRequired()}</InputLabel>
                                <RadioGroup
                                    row
                                    name="radioInputType"
                                    id="radioInputType"
                                    value={inputType}
                                    onChange={handleInputTypeChange}
                                >
                                    <FormControlLabel value="FISICO" control={<Radio />} label="Input Físico" />
                                    <FormControlLabel value="LOGICO" control={<Radio />} label="Input Lógico" />

                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="class-address">Endereço</InputLabel>
                                <TextField
                                    id="class-address"
                                    fullWidth
                                    variant="standard"
                                    placeholder={"Endereço"}
                                    onChange={(value) => setAddress(value.target.value)}
                                    value={address}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="class-ajuste">Regra de Conversão</InputLabel>
                                <TextField
                                    error={inputType === "LOGICO"}
                                    id="class-ajuste"
                                    fullWidth
                                    variant="standard"
                                    placeholder={"Ajuste"}
                                    onChange={(value) => setAdjustment(value.target.value)}
                                    value={adjustment}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="class-note">Descrição</InputLabel>
                                <TextField
                                    id="class-note"
                                    fullWidth
                                    variant="standard"
                                    placeholder={"Nota"}
                                    onChange={(value) => setNote(value.target.value)}
                                    value={note}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="selectUnidade">Unidade</InputLabel>
                                <Select
                                    MenuProps={MenuProps}
                                    fullWidth
                                    labelId="selectUnidade"
                                    id="selectUnidade"
                                    value={unit}
                                    label="Unidade"
                                    onChange={(value) => setUnit(value.target.value as number)}
                                    variant={"standard"}
                                    required
                                >
                                    {
                                        unitList.map((type: any) => {
                                            return (
                                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="class-name">Ponto DNP3 {TextRequired()}</InputLabel>
                                <TextField
                                    error={point === null && inputType !== "LOGICO"}
                                    fullWidth
                                    id="class-name"
                                    variant="standard"
                                    type={"number"}
                                    placeholder={"Ponto"}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setPoint(Number(value));
                                    }}
                                    value={point}
                                    required={inputType !== "LOGICO"}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="selectTipoEspecifico">Tipo Específico {TextRequired()}</InputLabel>
                                <Select
                                    MenuProps={MenuProps}
                                    fullWidth
                                    labelId="selectTipoEspecifico"
                                    id="selectTipoEspecifico"
                                    value={teleObjectSpecificationType}
                                    label="Tipo Específico"
                                    onChange={(value) => setTeleObjectSpecificationType(value.target.value as number)}
                                    variant={"standard"}
                                    required={inputType !== "LOGICO"}
                                >
                                    {
                                        teleObjectSpecificationTypesList.map((type: any) => {
                                            return (
                                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="selectTipoObjeto">Tipo de Objeto</InputLabel>
                                <Select
                                    MenuProps={MenuProps}
                                    fullWidth
                                    labelId="selectTipoObjeto"
                                    id="selectTipoObjeto"
                                    value={modBusObjectType}
                                    label="Tipo de Objeto"
                                    onChange={(value) => setModBusObjectType(value.target.value as number)}
                                    variant={"standard"}
                                >
                                    {
                                        modBusObjectTypesList.map((type: any) => {
                                            return (
                                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="selectTipoObjetoDataType">Tipo de Dado</InputLabel>
                                <Select
                                    MenuProps={MenuProps}
                                    fullWidth
                                    labelId="selectTipoObjetoDataType"
                                    id="selectTipoObjetoDataType"
                                    value={teleObjectDataType}
                                    label="Classificação do Dado"
                                    onChange={(value) => setTeleObjectDataType(value.target.value as number)}
                                    variant={"standard"}
                                    required
                                >
                                    {
                                        teleObjectDataTypeList.map((type: any) => {
                                            return (
                                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="class-name">Variação</InputLabel>
                                <TextField
                                    fullWidth
                                    id="class-name"
                                    variant="standard"
                                    type={"number"}
                                    placeholder={"Variação"}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setVariation(parseFloat(value));
                                    }}
                                    value={variation}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="class-name">Limite Inferior</InputLabel>
                                <TextField
                                    fullWidth
                                    id="class-name"
                                    variant="standard"
                                    placeholder={"Limite Inferior"}
                                    type={"number"}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setUnderLimit(truncateNumber(parseFloat(value), 2));
                                    }}
                                    value={underLimit}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="class-name">Limite Superior</InputLabel>
                                <TextField
                                    fullWidth
                                    id="class-name"
                                    variant="standard"
                                    type={"number"}
                                    placeholder={"Limite Superior"}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setUpperLimit(truncateNumber(parseFloat(value), 2));
                                    }}
                                    value={upperLimit}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                                <InputLabel id="class-name">Variáveis de Comando</InputLabel>
                                <TextField
                                    fullWidth
                                    id="class-name"
                                    variant="standard"
                                    placeholder={"Variáveis de Comando"}
                                    onChange={(value) => setCommandVariables(value.target.value)}
                                    value={commandVariables}
                                />
                            </Grid>
                        <Grid item xs={12} md={6} xl={4} marginBottom={2}>
                            <InputLabel id="default">Padrão {TextRequired()}</InputLabel>
                            <RadioGroup
                                row
                                name="default"
                                id="default"
                                value={isDefault}
                                onChange={handleDefaultChange}
                            >
                                <FormControlLabel value={"true"} control={<Radio disabled={!isAuthorizedToDefault}/>} label="Sim" />
                                <FormControlLabel value={"false"} control={<Radio disabled={!isAuthorizedToDefault}/>} label="Não" />

                            </RadioGroup>
                        </Grid>
                    </Grid>
                        </CustomTabPanel>

                    <CustomTabPanel value={valueTab} index={1}>
                        <Grid style={{height: window.innerWidth <= 2000 ? window.innerHeight / 3 + 10 :window.innerHeight / 2 + 10 , overflowY: 'auto'}}>
                            <Button
                                size={"small"}
                                variant="contained"
                                // component={Link}
                                onClick={() => handleChangeTab(null, listSeverityTabs.length + 1)}
                                endIcon={<AddCircle/>}>
                                Adicionar
                            </Button>
                            {listSeverityTabs.map((item) => (
                                <Box style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} key={item}>
                                    <AlarmConditionTab
                                        index={item}
                                        alarmSeveritiesList={alarmSeveritiesList}
                                        normalization={conditionsValue.find(c => c.index === item)}
                                        alarmSeverity={alarmSeveritiesValue.find(c => c.index === item)}
                                        alarmedName={alarmedNamesValue.find(c => c.index === item)}
                                        alarmSoundProps={alarmSoundValue.find(c => c.index === item)}
                                        setAlarmSoundCondition={handleSetAlarmSound}
                                        setNormalizationCondition={handleSetNormalization}
                                        setAlarmSeverityCondition={handleSetAlarmSeverity}
                                        setAlarmedNameCondition={handleSetAlarmedName}
                                        setDeleteItem={handleDeleteAlarmCondition}
                                    />

                                </Box>

                            ))}
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel index={2} value={valueTab}>
                        <CommandValueTab data={commandValueList} onDeleteCommand={onDeleteCommand} onSaveCommands={onSaveCommands} commandIdsDeleted={commandIdsDeleted}/>
                    </CustomTabPanel>

            </ACard>
            <ConfirmDialog
                    title={'Deletar'}
                    description={'Deseja deletar essa severidade?'}
                    open={openDialog}
                    handleConfirm={handleDialogConfirm}
                    handleClose={handleDialogClose}
                />
            </Grid>
        </Modal>
    )
}
export default function ModalPointClassHome({data, open, onCloseModal, dataForm, equipmentClass, refreshPointMap, index}: Readonly<PointClassModalProps>){
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <ModalPointClass data={data} open={open} onCloseModal={onCloseModal} dataForm={dataForm} equipmentClass={equipmentClass} refreshPointMap={refreshPointMap} index={index}/>
        </QueryClientProvider>
    )
}